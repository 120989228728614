//@ts-check

/**
 * Will add the missing keys between the two object, or keep the one from "source" in case it exist in both
 * @param {object} target The object to enhance
 * @param {object} source The object used to extend the first one
 * @returns {object} A combinaison of the two objects passed as argument.
 * If a property from the second object does not exist in the first one then it will be added.
 * In case it exist it will replace it
 */
const mergeDeep = (target, source) => {
  const isObject = item =>
    item && typeof item === "object" && !Array.isArray(item);
  const isArray = item =>
    item && typeof item === "object" && Array.isArray(item);

  if (!source) return target;

  const output = Object.assign({}, target);

  Object.keys(source).forEach(key => {
    if (isObject(target[key]) && isObject(source[key])) {
      output[key] = mergeDeep(target[key], source[key]);
    } else if (isArray(target[key]) && isArray(source[key])) {
      const newArray = [];
      source[key].forEach((item, index) => {
        if (isObject(item) && isObject(target[key][index])) {
          newArray.push(mergeDeep(item, target[key][index]));
        } else {
          newArray.push(item);
        }
      });
      output[key] = newArray;
    } else {
      output[key] = source[key];
    }
  });

  return output;
};

export default mergeDeep;
