import { getIframeData } from "Utils/classConfig";
import { setupFindContainers } from "../utils/containersObserver";
import { PUBLIDATA_OBJECT, WEB_COMPONENTS } from "Utils/constants";
import PublidataWidget from "WebComponents/PublidataWidget";

class Widget {
  constructor() {
    this.$iframes = {};
    this.$containers = [];
    this.hasV1 = false;

    this.run();
  }

  run() {
    this.catchUpV1();
    this.findContainers();
  }

  catchUpV1() {
    const pw =
      window[window.PublidataWidgetObject] || window[window.PublidataObject];
    if (!pw || !pw.q) return;

    const [_, widgetId] = pw.q[0];
    if (!widgetId) return;

    const $oldContainer = document.getElementById("widget-container");
    if (!$oldContainer) return;

    this.hasV1 = true;

    $oldContainer.classList.add(
      `${WEB_COMPONENTS.IFRAME.WIDGET_ID_PREFIX}${widgetId}`
    );
  }

  refresh() {
    const $newContainers = document.querySelectorAll(
      `${WEB_COMPONENTS.IFRAME.CONTAINER_SELECTOR}:not([data-initialized])`
    );

    if (!$newContainers || !$newContainers.length) return;

    this.$containers = new Set([...this.$containers, ...$newContainers]);
    $newContainers.forEach(this.initWidget.bind(this));
  }

  findContainers() {
    const callback = containers => {
      this.$containers = containers;
      PublidataWidget.init();
      this.initWidgets();
      this.setEventListeners();
    };
    setupFindContainers(WEB_COMPONENTS.IFRAME.CONTAINER_SELECTOR, callback);
  }

  initWidgets() {
    this.$containers.forEach(this.initWidget.bind(this));
  }

  initWidget($container) {
    const { containerId, url } = getIframeData($container);
    if (!url || !containerId) return;

    $container.setAttribute("data-containerId", containerId);
    this.$iframes[containerId] = PublidataWidget.create(containerId, url);
    $container.innerHTML = "";
    $container.appendChild(this.$iframes[containerId]);
    $container.setAttribute("data-initialized", true);

    // Insert title for rgaa compliance
    this.$iframes[containerId].setAttribute("title", "Widget déchets");
  }

  setEventListeners() {
    window.addEventListener("message", this.onMessage.bind(this));
  }

  getTargetFrame(containerId) {
    if (!containerId) {
      const v1Wrapper = document.getElementById("widget-container");
      const v1Iframe = v1Wrapper?.querySelector("iframe");
      return v1Iframe;
    }

    let $targetFrame = window[PUBLIDATA_OBJECT].Widget.$iframes[containerId];
    const existInDOM = document.body.contains($targetFrame);

    if (!existInDOM) {
      let $targetFrame = document.querySelector(
        `iframe[name="${containerId}"]`
      );
      window[PUBLIDATA_OBJECT].Widget.$iframes[containerId] = $targetFrame;
    }

    return $targetFrame;
  }

  onMessage(event) {
    const { containerId } = event.data;
    // Ingore react devtools
    if (event.data.source === "react-devtools-content-script") return;
    if (!containerId && !this.hasV1) return;

    const $targetFrame = this.getTargetFrame(containerId);
    if (!$targetFrame) return;

    switch (event.data.event) {
      case "onInit":
        $targetFrame.onSrcInit(event.data);
        break;
      case "onNavigate":
        $targetFrame.onSrcNavigate(event.data, this.hasV1);
        break;
      case "hasInsertScript":
        $targetFrame.sendInitMessage();
        break;
      case "onResize":
        $targetFrame.onResizeContainer(event.data.height);
        break;
      case "onNegativeScrollResize":
        $targetFrame.onNegativeScrollResize(event.data);
        break;
      case "onRequestParentWindowData":
        $targetFrame.onSrcRequestParentWindowData();
        break;
      case "instanceToParentWindow":
        $targetFrame.onReceiveSrcInstance(event.data);
        break;
      case "scrollToWidget":
        $targetFrame.onScrollToTop({
          forceScroll: true,
          behavior: event.data.behavior
        });
        break;
      case "onScrollToBottom":
        $targetFrame.onScrollToBottom();
        break;
      case "onScrollTo":
        $targetFrame.onScrollTo(event.data.position);
        break;
      case "redirect":
        $targetFrame.onRedirect(event.data.url);
        break;
      default:
        break;
    }
  }
}

export default Widget;
