const checkContainers = (query, callback, observer) => {
  const containers = document.querySelectorAll(query);

  if (containers.length) {
    callback(containers);
    observer.disconnect(); // Arrêter l'observation une fois les conteneurs trouvés
  }
};

const createObserver = (query, callback) => {
  const observer = new MutationObserver(() => {
    checkContainers(query, callback, observer);
  });

  // Observer les changements dans le DOM
  observer.observe(document.body, { childList: true, subtree: true });

  // run the check on the initial page load
  checkContainers(query, callback, observer);

  return observer;
};

const resetObserver = (observer, query, callback) => {
  observer.disconnect();
  return createObserver(query, callback);
};

const setupHistoryListener = resetObserver => {
  window.addEventListener("popstate", () => resetObserver());

  // Surveiller les changements d'URL effectués via `pushState` ou `replaceState`
  const interceptHistory = method => {
    const originalMethod = history[method];
    history[method] = (...args) => {
      originalMethod.apply(history, args);
      resetObserver();
    };
  };

  interceptHistory("pushState");
  interceptHistory("replaceState");
};

export const setupFindContainers = (query, callback) => {
  const observer = createObserver(query, callback);

  setupHistoryListener(() => resetObserver(observer, query, callback));
};
