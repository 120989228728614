// import "@ungap/custom-elements";
import mergeDeep from "Utils/mergeDeep";
import { getWidgetId } from "Utils/classConfig";
import { WEB_COMPONENTS, PUBLIDATA_OBJECT } from "Utils/constants";

// ------------
// CORE
// ------------

class PublidataWidget extends window.HTMLIFrameElement {
  constructor() {
    super();

    this.isModal = false;
    this.initialized = false;
    this.negativeScrollHeight = 0;

    this.instance = {
      custom_settings: {
        onNavigateOffset: [80, 20]
      }
    };

    this.setStyle();
    this.setAttributes();
  }

  // 0 - Native method

  connectedCallback() {
    this.containerId = String(this.getAttribute("name"));
    if (
      window[PUBLIDATA_OBJECT] &&
      window[PUBLIDATA_OBJECT].Widget.$iframes[this.containerId]
    )
      window[PUBLIDATA_OBJECT].Widget.$iframes[this.containerId] = this;
    this.initContainer();
  }

  // 1 - Triggered on init

  setStyle() {
    this.style.width = "100%";
    this.style.height = "100%";
    this.style.border = "none";
  }

  setAttributes() {
    this.setAttribute("scrolling", "no");
    this.setAttribute("allowfullscreen", "");
    this.setAttribute("ms-allowfullscreen", "");
    this.setAttribute("allow", "geolocation *;camera;microphone");
  }

  initContainer() {
    this.$container = document.querySelector(
      `[data-containerId='${this.containerId}']`
    );
    const { modal } = this.$container?.dataset || {};
    const className = this.$container?.classList.value;
    this.isModal = Boolean(modal);
    this.isCCU = className.includes(
      WEB_COMPONENTS.CCU_IFRAME.CLASS_NAMES.CONTAINER
    );

    // TO BE REMOVED. SEE SLACK THREAD FOR MORE INFO: https://publidata.slack.com/archives/GDS3HFZAM/p1691755396419309
    const widgetId = getWidgetId(this.$container);
    const isBrokenWidget = widgetId === "mQLmrtb4VM";

    this.$container.style.height = this.isModal
      ? `calc(${WEB_COMPONENTS.IFRAME.MIN_HEIGHT}px + (${WEB_COMPONENTS.MODAL.BORDER_RADIUS} / 2)))`
      : this.isCCU
      ? `${WEB_COMPONENTS.CCU_IFRAME.MIN_HEIGHT}px`
      : `${isBrokenWidget ? 920 : WEB_COMPONENTS.IFRAME.MIN_HEIGHT}px`;
  }

  // 2 - Handle component in DOM

  scrollToTop(args = { forceScroll: false, behavior: "instant" }) {
    const { forceScroll, behavior } = args;
    if (this.isModal || !this.isFocus()) return;

    if (this.srcIsForm() && !forceScroll) return;

    const offset = this.getOffset();

    const yScroll =
      window.scrollY + // The scroll position within the page
      this.$container.getBoundingClientRect().top + // The top of the iframe
      this.negativeScrollHeight; // A part of the iframe that we wish to hide(negative scroll)

    window.scrollTo({
      top: yScroll - offset,
      behavior: behavior
    });
  }

  scrollToBottom() {
    if (!this.isFocus()) return;

    if (this.isModal) {
      // Scroll through the entire modal
      this.$container.scrollTo({
        top: this.$container.offsetHeight
      });
      return;
    }

    this.$container.scrollIntoView(false);
  }

  scrollTo(position) {
    if (!this.isFocus()) return;

    const container = this.$container || window;
    const offsetModal = container.offsetTop;

    const offset = this.getOffset();

    const yScroll =
      window.scrollY +
      position + // The position we want to scroll to
      this.$container.getBoundingClientRect().top + // The top of the iframe
      this.negativeScrollHeight; // A part of the iframe that we wish to hide(negative scroll)

    const yScrollModal = offsetModal + position + this.negativeScrollHeight;

    if (this.isModal) {
      container.scrollTo({
        top: yScrollModal,
        left: 0,
        behavior: "smooth"
      });
    } else {
      window.scrollTo({
        top: yScroll - offset,
        left: 0,
        behavior: "smooth"
      });
    }
  }

  onResizeContainer(height) {
    const newHeight = String(height).match(/px|vh|%/) ? height : `${height}px`;
    if (this.$container) {
      this.$container.style.height = this.isModal
        ? `calc(${newHeight} + (${WEB_COMPONENTS.MODAL.BORDER_RADIUS} / 2)))`
        : newHeight;
    }
    this.style.height = newHeight;
  }

  // 3 - Handle entering post messages from related frame

  onSrcInit({ height }) {
    this.initialized = true;
    this.onResizeContainer(height);
    this.sendProtocol();
    this.sendInstanceRequest();
  }

  onSrcNavigate(data, hasV1) {
    if (this.initialized) this.scrollToTop(data.forceScroll);
    else this.onSrcInit(data);
    if (hasV1) this.onResizeContainer(data.height);
  }

  onReceiveSrcInstance(data) {
    if (data && data.instance) {
      this.instance = mergeDeep(this.instance, data.instance);
    }
  }

  onSrcRequestParentWindowData() {
    this.sendParentWindowData();
  }

  onScrollToTop(forceScroll) {
    this.scrollToTop(forceScroll);
  }

  onScrollToBottom() {
    this.scrollToBottom();
  }

  onScrollTo(position) {
    this.scrollTo(position);
  }

  onRedirect(url) {
    window.location.href = url;
  }

  onNegativeScrollResize(data) {
    if (data) this.negativeScrollHeight = data.height || 0;
  }

  // 4 - Post messages sending

  sendInitMessage() {
    this.sendPostMessage({
      event: "onInit",
      initialized: true
    });
  }

  sendProtocol() {
    this.sendPostMessage({
      isHttps: document.location.protocol === "https:"
    });
  }

  sendInstanceRequest() {
    this.sendPostMessage({
      event: "getInstance"
    });
  }

  sendParentWindowData() {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;
    const screenResolution = `${screenWidth}x${screenHeight}`;
    this.sendPostMessage({
      event: "onSendParentWindowData",
      parentWindowUrl: window.location.href,
      parentWindowResolution: screenResolution
    });
  }

  // 5 - Helpers

  isFocus() {
    const elem = document.activeElement;
    return elem && elem.tagName === "IFRAME" && elem === this;
  }

  srcIsForm() {
    const regex = new RegExp("http(s)?://form.(staging.)?publidata./");
    return this.src.match(regex);
  }

  sendPostMessage(body) {
    const content = this.contentWindow || this.contentDocument;
    if (content) {
      content.postMessage(body, "*");
    }
  }

  getOffset() {
    const { onNavigateOffset } = this.instance?.custom_settings || {};

    const isMobileSize = window.innerWidth <= 640;
    const [desktopOffset, mobileOffset] = onNavigateOffset;
    /**
     * 'offset' is the space between the top of the iframe and the top of the page.
     * Usefull in case we have a fixed header.
     */
    const offset = isMobileSize ? mobileOffset : desktopOffset;
    return offset;
  }

  get $frame() {
    if (this.isModal) return this.$container || window;
    return window;
  }
}

// ------------
// EXPORT
// ------------

export default {
  init: () => {
    window.customElements.get(WEB_COMPONENTS.IFRAME.NAME) ||
      window.customElements.define(
        WEB_COMPONENTS.IFRAME.NAME,
        PublidataWidget,
        {
          extends: "iframe"
        }
      );
  },

  create: (name, src) => {
    const $iframe = document.createElement("iframe", {
      is: WEB_COMPONENTS.IFRAME.NAME
    });

    $iframe.src = src;
    $iframe.name = String(name);
    return $iframe;
  }
};
