/* global window, document */
import "Styles/modal.scss";
import { WEB_COMPONENTS } from "Utils/constants";

class PublidataModal extends window.HTMLElement {
  constructor() {
    super();

    this.$wrapper = document.createElement("div");
    this.$wrapper.classList.add(WEB_COMPONENTS.MODAL.CLASS_NAMES.WRAPPER);

    this.$container = document.createElement("div");
    this.$container.classList.add(WEB_COMPONENTS.IFRAME.CLASS_NAMES.CONTAINER);
    this.$container.setAttribute("data-modal", true);

    this.$closeButton = document.createElement("button");
    this.$closeButton.innerHTML = WEB_COMPONENTS.MODAL.CLOSE_BUTTON_TEXT;
    this.$closeButton.classList.add(
      WEB_COMPONENTS.MODAL.CLASS_NAMES.CLOSE_BUTTON
    );
  }

  connectedCallback() {
    this.close();
    this.renderModal();
  }

  renderModal() {
    // Click inside an iframe will not be detected
    this.onclick = () => this.close();

    this.$wrapper.appendChild(this.$closeButton);
    this.$wrapper.appendChild(this.$container);
    this.appendChild(this.$wrapper);
  }

  setWidget(widgetId) {
    this.$container.classList.add(
      `${WEB_COMPONENTS.IFRAME.WIDGET_ID_PREFIX}${widgetId}`
    );
  }

  open() {
    this.removeAttribute("hidden");
  }

  close() {
    this.setAttribute("hidden", "");
  }
}

export default {
  init: () => {
    window.customElements.get(WEB_COMPONENTS.MODAL.NAME) ||
      window.customElements.define(WEB_COMPONENTS.MODAL.NAME, PublidataModal);
  },

  create: widgetId => {
    const modal = document.createElement(WEB_COMPONENTS.MODAL.NAME);
    modal.setWidget(widgetId);
    document.querySelector(WEB_COMPONENTS.MODAL.PARENT_NODE).appendChild(modal);
    return modal;
  }
};
