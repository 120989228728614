// @ts-check
import { DEFAULT_WIDGET_HOST, WEB_COMPONENTS } from "./constants";

/**
 * Will fetch the config passed using classNames
 * @param {HTMLElement} $element The Html element we need to parse
 * @param {String} classNamePrefix The prefix used to identify the config class
 * @returns {String} The className wich was retrieved without the prefix
 */
export const getClassConfig = ($element, classNamePrefix) => {
  const rawClass = $element.classList.value
    .split(" ")
    .find(rawClass => rawClass.includes(classNamePrefix));

  return rawClass ? rawClass.replace(classNamePrefix, "") : "";
};

/**
 * Will get the widgetId from the 'widget-id-XXXX' class
 * @param {HTMLElement} $element
 * @return {String}
 */
export const getWidgetId = $element =>
  getClassConfig($element, WEB_COMPONENTS.IFRAME.WIDGET_ID_PREFIX);

/**
 * Will get the action from the 'action-XXXX' class
 * @param {HTMLElement} $element - The HTML element we need to parse
 * @returns {String} The action wich was retrieved without the prefix
 */
export const getClassAction = $element => {
  const rawClass = $element.classList.value
    .split(" ")
    .find(rawClass =>
      ["action-open-modal", "action-target-blank"].includes(rawClass)
    );

  return rawClass ? rawClass.replace("action-", "") : "";
};

/**
 * Will generate the URL based on the widgetId used in the class
 * @param {String} widgetId
 * @param {String | null} containerId
 * @param {Boolean} isCCUContainer
 * @returns {String | undefined}
 */
export const getWidgetUrl = (
  widgetId,
  containerId = null,
  isCCUContainer = false
) => {
  // Allow us to overide the widgetId using the URL
  const widgetParamUrl = new URLSearchParams(window?.location.search).get(
    "publidataWidgetUrl"
  );
  const _widgetId = widgetParamUrl || widgetId;

  if (!_widgetId) return;

  let url =
    _widgetId.indexOf("http") === -1
      ? `${DEFAULT_WIDGET_HOST}${
          DEFAULT_WIDGET_HOST.endsWith("/") ? "" : "/"
        }${_widgetId}`
      : _widgetId;

  if (containerId) {
    const newUrl = new URL(url);
    newUrl.searchParams.set("containerId", containerId);
    url = newUrl.toString();
  }

  const currentUrl = window.location.href;
  if (currentUrl && isCCUContainer) {
    const newUrl = new URL(url);
    newUrl.searchParams.set("containerUrl", currentUrl);
    url = newUrl.toString();
  }

  const newUrl = new URL(url);
  newUrl.searchParams.set("isInserted", "true");
  url = newUrl.toString();

  return url;
};

/**
 *
 * @param {HTMLElement} $element The HTML container with the required classes
 * @returns {Object}
 */
export const getIframeData = $element => {
  if (!$element) return {};
  const widgetId = getWidgetId($element);
  const isCCUContainer = $element.classList.contains(
    WEB_COMPONENTS.CCU_IFRAME.CLASS_NAMES.CONTAINER
  );
  const containerId = Number(new Date()) / 1 + Math.floor(Math.random() * 100);
  return {
    containerId,
    widgetId,
    url: getWidgetUrl(widgetId, String(containerId), isCCUContainer)
  };
};

/**
 * Will return an object containing the 'widgetId' and the 'action'
 * @param {HTMLElement} $element The HTML button with the required classes
 * @returns {{widgetId: String, action: String} | null}
 */
export const getButtonData = $element => {
  if (!$element) return null;

  return {
    widgetId: getClassConfig($element, WEB_COMPONENTS.MODAL.WIDGET_ID_PREFIX),
    action: getClassAction($element)
  };
};
