import { WEB_COMPONENTS, PUBLIDATA_OBJECT } from "Utils/constants";
import { getButtonData, getWidgetUrl } from "Utils/classConfig";
import { setupFindContainers } from "Utils/containersObserver";

import PublidataModal from "WebComponents/PublidataModal";

class Button {
  constructor() {
    this.$modals = {};
    this.$buttons = [];

    this.run();
  }

  run() {
    this.findButtons();
  }

  refresh() {
    const $newButtons = document.querySelectorAll(
      `${WEB_COMPONENTS.MODAL.BUTTON_SELECTOR}:not([data-initialized])`
    );

    if (!$newButtons || !$newButtons.length) return;

    this.$buttons = new Set([...this.$buttons, ...$newButtons]);
    this.initButtons($newButtons);
    this.refreshContainers();
  }

  refreshContainers() {
    if (window[PUBLIDATA_OBJECT] && window[PUBLIDATA_OBJECT].Widget)
      window[PUBLIDATA_OBJECT].Widget.refresh();
  }

  findButtons() {
    const callback = buttons => {
      this.$buttons = buttons;
      PublidataModal.init();
      this.initButtons();
    };

    setupFindContainers(WEB_COMPONENTS.MODAL.BUTTON_SELECTOR, callback);
  }

  initButtons($buttons = this.$buttons) {
    $buttons.forEach(this.initButton.bind(this));
  }

  initButton($button) {
    const { widgetId, action } = getButtonData($button);

    if (!widgetId || !action) return;

    switch (action) {
      case "open-modal":
        this.initModal($button, widgetId);
        break;
      case "target-blank":
        this.initLink($button, widgetId);
        break;
      default:
        break;
    }

    $button.setAttribute("data-initialized", true);
  }

  initModal($button, widgetId) {
    // We only need one modal by widgetId
    if (!this.$modals[widgetId])
      this.$modals[widgetId] = PublidataModal.create(widgetId);

    $button.addEventListener("click", () => this.$modals[widgetId].open());
  }

  initLink($button, widgetId) {
    $button.addEventListener("click", () => {
      const newTab = window.open(getWidgetUrl(widgetId), "_blank");
      newTab.focus();
    });
  }
}

export default Button;
