export const PUBLIDATA_OBJECT = "PublidataInstance";
export const V1_PUBLIDATA_OBJECT = "PublidataWidgetObject";
/**
 * @REMINDER: widgets.publidata.io is redirected to next..publidata.io
 * for all waste collection widgets
 */
export const DEFAULT_WIDGET_HOST = process.env.DEFAULT_WIDGET_HOST;

export const WEB_COMPONENTS = {
  IFRAME: {
    MIN_HEIGHT: 700,
    NAME: "publidata-iframe",
    WIDGET_ID_PREFIX: "widget-id-",
    CONTAINER_SELECTOR: "#widget-container, .publidata-widget-container",
    CLASS_NAMES: {
      CONTAINER: "publidata-widget-container"
    }
  },
  CCU_IFRAME: {
    MIN_HEIGHT: 0,
    CLASS_NAMES: {
      CONTAINER: "publidata-ccu-container"
    }
  },
  MODAL: {
    PARENT_NODE: "body",
    NAME: "publidata-modal",
    WIDGET_ID_PREFIX: "widget-id-",
    CLOSE_BUTTON_TEXT: "Retour au site",
    BORDER_RADIUS: "var(--publidata-modal-border-radius)",
    BUTTON_SELECTOR: ".publidata-widget-button[class*='action-']",
    CLASS_NAMES: {
      WRAPPER: "publidata-modal-wrapper",
      CLOSE_BUTTON: "publidata-modal-close"
    }
  }
};
