import "@publidata/utils-custom-element/dist";
import Button from "Scripts/Button";
import Widget from "Scripts/Widget";

import { PUBLIDATA_OBJECT } from "Utils/constants";

(() => {
  const object = {};

  if (!window[PUBLIDATA_OBJECT]) {
    object.Button = new Button();
    object.Widget = new Widget();
    window[PUBLIDATA_OBJECT] = object;
  } else {
    /* Handle scripts and container loaded with delay,
     * after DOM was first rendered or in case the script is injected later
     * (modals, post cookie consentement, React env or any kind of virtual DOMs...)
     */
    Object.keys(window[PUBLIDATA_OBJECT]).forEach(key => {
      if (typeof window[PUBLIDATA_OBJECT][key].refresh !== "function") return;
      window[PUBLIDATA_OBJECT][key].refresh();
    });
  }
})();
